var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{ref:"form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name-input","invalid-feedback":"Name is required"}},[_c('b-form-input',{class:{
          'is-invalid': _vm.submitted && _vm.v$.name.$error,
        },attrs:{"id":"name-input","state":_vm.validateState('name'),"placeholder":"enter a name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('b-form-group',{attrs:{"label":"Short Name","label-for":"short-name-input","invalid-feedback":"Short name is required"}},[_c('b-form-input',{class:{
          'is-invalid': _vm.submitted && _vm.v$.short_name.$error,
        },attrs:{"id":"short-name-input","state":_vm.validateState('short_name'),"placeholder":"enter a short name. example INR or USD","formatter":_vm.formatter},model:{value:(_vm.short_name),callback:function ($$v) {_vm.short_name=$$v},expression:"short_name"}}),(_vm.submitted && _vm.v$.short_name.maxLength)?_c('small',{staticClass:"invalid-feedback"},[_vm._v(" maximum 4 character is allowed ")]):_vm._e()],1),_c('b-form-group',{attrs:{"label":"Phone Code","label-for":"phone-code-input","invalid-feedback":"Phone code is required"}},[_c('b-form-input',{class:{
          'is-invalid': _vm.submitted && _vm.v$.phone_code.$error,
        },attrs:{"id":"phone-code-input","state":_vm.validateState('phone_code'),"placeholder":"enter a phone code. example INR or USD","formatter":_vm.formatter},model:{value:(_vm.phone_code),callback:function ($$v) {_vm.phone_code=$$v},expression:"phone_code"}}),(_vm.submitted && _vm.v$.phone_code.maxLength)?_c('small',{staticClass:"invalid-feedback"},[_vm._v(" maximum 4 character is allowed ")]):_vm._e()],1),_c('b-form-group',{attrs:{"label":"Status","label-for":"status-input","invalid-feedback":"Status is required"}},[_c('b-form-checkbox',{class:{
          'is-invalid': _vm.submitted && _vm.v$.status.$error,
        },attrs:{"name":"check-status","state":_vm.validateState('status'),"switch":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" "+_vm._s(_vm.status ? "Active" : "Inactive")+" ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }